import Relation from '@/dc-it/types/Relation'
import FaqCategory from '@/dc-it/models/FaqCategory'
import Field from '../types/Field'
import HTMLField from '../types/HTMLField'
import Model from './Model'

export default class FrequentQuestion extends Model {
    endpoint = 'frequent_questions';

    related = ['faq_category']

    fields = [
      new Field('id', 'Código').disabled(),
      new Relation('faq_category', 'Categoría', FaqCategory, 'name_es'),
      new Field('question_es', 'Pregunta (español)'),
      new Field('question_en', 'Pregunta (inglés)'),
      new HTMLField('answer_es', 'Respuesta (español)').hide(),
      new HTMLField('answer_en', 'Respuesta (inglés)').hide(),
      new Field('order', 'Orden').hide(),

    ]

    clone = () => FrequentQuestion;
}
